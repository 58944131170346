.home-child,
.home-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 845.93px;
  height: 817.06px;
}
.home-item {
  width: 315.56px;
  height: 299.25px;
}
.assist,
.image {
  position: absolute;
  top: 9px;
  left: 46.11px;
}
.assist {
  top: 24px;
  left: 47.11px;
  font-size: 6px;
  letter-spacing: 0.78em;
  font-family: var(--font-montaga);
}
.image-parent,
.x-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 99.11px;
  height: 44.33px;
}
.image-parent {
  top: 30px;
  left: 42px;
  font-size: 13px;
}
.home1,
.why-us {
  position: absolute;
  top: 0;
}
.home1 {
  left: 0;
  color: var(--color-blue);
}
.why-us {
  left: 97px;
}
.account,
.home-parent,
.xl-premium {
  position: absolute;
  top: 0;
  left: 207px;
}
.account,
.home-parent {
  left: 357px;
}
.home-parent {
  top: 40px;
  left: 248px;
  width: 429px;
  height: 21px;
  font-size: var(--font-size-mid);
}
.group-child {
  position: absolute;
  top: -0.5px;
  left: -0.5px;
  width: 317px;
  height: 144px;
}
.growing-business-with {
  position: absolute;
  top: 58px;
  left: 86px;
  line-height: 57px;
  font-weight: 900;
  background: radial-gradient(50% 50%at 50% 50%, #fff 28.13%, #522cff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  width: 492px;
}
.vector-parent {
  position: absolute;
  top: 120px;
  left: 8px;
  width: 578px;
  height: 229px;
  font-size: 47px;
}
.lorem-ipsum-dolor {
  position: absolute;
  top: 390px;
  left: 92px;
  font-size: 16px;
  line-height: 28px;
  display: inline-block;
  width: 425px;
}
.home-inner {
  position: absolute;
  top: 0;
  left: 517.5px;
  width: 762.5px;
  height: 495px;
  object-fit: cover;
}
.group-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 190px;
  height: 190px;
}
.hicon-bold-play-circle {
  position: absolute;
  top: 34.94px;
  left: 32.94px;
  width: 124.12px;
  height: 124.12px;
  overflow: hidden;
}
.l,
.p {
  position: absolute;
}
.p {
  top: 0;
  left: 18.99px;
  line-height: 30px;
  font-weight: 900;
  transform: rotate(39.28deg);
  transform-origin: 0 0;
}
.l {
  top: 10.87px;
  left: 30.32px;
  transform: rotate(50.97deg);
}
.a,
.l,
.y {
  line-height: 30px;
  font-weight: 900;
  transform-origin: 0 0;
}
.a {
  position: absolute;
  top: 21.87px;
  left: 40.33px;
  transform: rotate(57.55deg);
}
.y {
  top: 34.87px;
  left: 47.63px;
  transform: rotate(67.01deg);
}
.i,
.v,
.y {
  position: absolute;
}
.v {
  top: 55.5px;
  left: 54.98px;
  line-height: 30px;
  font-weight: 900;
  transform: rotate(81.1deg);
  transform-origin: 0 0;
}
.i {
  top: 71.32px;
  left: 57.65px;
  transform: rotate(86.93deg);
}
.d,
.e,
.i {
  line-height: 30px;
  font-weight: 900;
  transform-origin: 0 0;
}
.d {
  position: absolute;
  top: 81.86px;
  left: 57.43px;
  transform: rotate(91.89deg);
}
.e {
  top: 100.82px;
  left: 54.93px;
  transform: rotate(107.36deg);
}
.e,
.o,
.p-parent {
  position: absolute;
}
.o {
  top: 113.69px;
  left: 50.69px;
  line-height: 30px;
  font-weight: 900;
  transform: rotate(111.15deg);
  transform-origin: 0 0;
}
.p-parent {
  top: 0.13px;
  left: 107px;
  width: 57.97px;
  height: 125.81px;
}
.ellipse-parent,
.group-inner,
.group-parent {
  position: absolute;
  top: 0;
  left: 0;
  width: 154px;
  height: 154px;
}
.ellipse-parent,
.group-parent {
  top: 20px;
  left: 19px;
  width: 164.97px;
}
.ellipse-parent {
  top: 120px;
  left: 635px;
  width: 190px;
  height: 190px;
  font-size: var(--font-size-mini);
}
.ellipse-icon {
  position: absolute;
  top: 59px;
  left: 263px;
  border-radius: 7px;
  width: 21px;
  height: 21px;
}
.group-icon {
  position: absolute;
  top: 508px;
  left: 784px;
  width: 170px;
  height: 170px;
}
.advertise-ment {
  position: absolute;
  top: 618px;
  left: 809px;
  line-height: 23px;
  font-weight: 900;
  display: inline-block;
  width: 109px;
}
.adwords-2-1,
.div {
  position: absolute;
  top: 529px;
}
.div {
  left: 903px;
  font-size: var(--font-size-41xl);
  line-height: 30px;
  font-weight: 900;
  -webkit-text-stroke: 1px #2d00f7;
}
.adwords-2-1 {
  left: 812px;
  width: 68px;
  height: 68px;
  object-fit: cover;
}
.home-child1 {
  position: absolute;
  top: 508px;
  left: 581px;
  width: 170px;
  height: 170px;
}
.div1 {
  position: absolute;
  top: 529px;
  left: 700px;
  font-size: var(--font-size-41xl);
  line-height: 30px;
  font-weight: 900;
  -webkit-text-stroke: 1px #2d00f7;
}
.personali-zed-offers,
.product-editing {
  position: absolute;
  top: 618px;
  left: 604px;
  line-height: 23px;
  font-weight: 900;
  display: inline-block;
  width: 103px;
}
.personali-zed-offers {
  left: 1010px;
  width: 124px;
}
.home-child2 {
  position: absolute;
  top: 508px;
  left: 987px;
  width: 170px;
  height: 170px;
}
.div2 {
  position: absolute;
  top: 529px;
  left: 1098px;
  font-size: var(--font-size-41xl);
  line-height: 30px;
  font-weight: 900;
  -webkit-text-stroke: 1px #2d00f7;
}
.home-child3 {
  position: absolute;
  top: 508px;
  left: 378px;
  width: 170px;
  height: 170px;
}
.brand-creation,
.div3 {
  position: absolute;
  font-weight: 900;
}
.div3 {
  top: 529px;
  left: 506px;
  font-size: var(--font-size-41xl);
  line-height: 30px;
  -webkit-text-stroke: 1px #2d00f7;
}
.brand-creation {
  top: 618px;
  left: 399px;
  line-height: 23px;
  display: inline-block;
  width: 103px;
}
.brand-image-3-1,
.discount-2-1,
.rgb-1-icon {
  position: absolute;
  top: 526px;
  left: 1010px;
  width: 74px;
  height: 74px;
  object-fit: cover;
}
.brand-image-3-1,
.rgb-1-icon {
  left: 399px;
}
.rgb-1-icon {
  left: 604px;
}
.rectangle-div,
.unsplashbjnxplgnji0-icon {
  position: absolute;
  top: 0;
  left: 1px;
  width: 1280px;
  height: 723px;
}
.unsplashbjnxplgnji0-icon {
  object-fit: cover;
}
.rectangle-div {
  background: radial-gradient(50% 50%at 50% 50%, rgba(11, 11, 12, 0), #0b0b0c);
}
.line-div,
.unsplashbjnxplgnji0-parent {
  position: absolute;
  top: 0;
  left: 0;
  width: 1282px;
  height: 723px;
}
.line-div {
  top: 308px;
  left: 1px;
  backdrop-filter: blur(16px);
  border-top: 1px solid var(--color-white);
  box-sizing: border-box;
  width: 1280px;
  height: 0;
}
.group-child1,
.line-icon {
  position: absolute;
  top: 148.41px;
  left: -3.59px;
  width: 27.17px;
  height: 27.17px;
}
.line-icon {
  top: 26px;
  left: 9.5px;
  width: 56px;
  height: 112px;
}
.step-01 {
  position: absolute;
  top: 0;
  left: 89px;
  font-weight: 900;
}
.group-child2 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-3xs);
  background-color: var(--color-gray-100);
  backdrop-filter: blur(5px);
  width: 290px;
  height: 66px;
}
.you-give-us {
  position: absolute;
  top: 21px;
  left: 42px;
}
.rectangle-parent {
  position: absolute;
  top: 72px;
  left: 37px;
  width: 290px;
  height: 66px;
  font-size: var(--font-size-xl);
}
.group-div {
  position: absolute;
  top: 146px;
  left: 70px;
  width: 327px;
  height: 172px;
}
.group-child3,
.group-child4 {
  position: absolute;
  top: 199.41px;
  left: -3.59px;
  width: 27.17px;
  height: 27.17px;
}
.group-child4 {
  top: 29px;
  left: 10px;
  width: 55px;
  height: 160px;
}
.step-03 {
  position: absolute;
  top: 0;
  left: 54px;
  font-weight: 900;
}
.group-child5 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-3xs);
  background-color: var(--color-gray-100);
  backdrop-filter: blur(5px);
  width: 387px;
  height: 116px;
}
.your-order-is {
  position: absolute;
  top: 21px;
  left: 34px;
  display: inline-block;
  width: 320px;
}
.rectangle-group {
  position: absolute;
  top: 73px;
  left: 0;
  width: 387px;
  height: 116px;
  text-align: center;
  font-size: var(--font-size-xl);
}
.group-parent1,
.step-03-parent {
  position: absolute;
  top: 0;
  left: 37px;
  width: 387px;
  height: 189px;
}
.group-parent1 {
  top: 95px;
  left: 548px;
  width: 424px;
  height: 223px;
}
.group-child6,
.group-child7 {
  position: absolute;
  top: -3.59px;
  left: -3.59px;
  width: 27.17px;
  height: 27.17px;
}
.group-child7 {
  top: 33px;
  left: 9px;
  width: 56px;
  height: 112px;
}
.step-02 {
  position: absolute;
  top: 114px;
  left: 85px;
  font-weight: 900;
}
.group-child8 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-3xs);
  background-color: var(--color-gray-100);
  backdrop-filter: blur(5px);
  width: 290px;
  height: 91px;
}
.we-take-it {
  position: absolute;
  top: 21px;
  left: 34px;
  display: inline-block;
  width: 222px;
}
.rectangle-container {
  position: absolute;
  top: 186px;
  left: 37px;
  width: 290px;
  height: 91px;
  text-align: center;
  font-size: var(--font-size-xl);
}
.group-parent2 {
  position: absolute;
  top: 298px;
  left: 309px;
  width: 327px;
  height: 277px;
}
.step-04 {
  position: absolute;
  top: 114px;
  left: 83px;
  font-weight: 900;
}
.group-child11 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-3xs);
  background-color: var(--color-gray-100);
  backdrop-filter: blur(5px);
  width: 290px;
  height: 118px;
}
.with-our-support {
  position: absolute;
  top: 21px;
  left: 24px;
  display: inline-block;
  width: 244px;
}
.rectangle-parent1 {
  position: absolute;
  top: 186px;
  left: 37px;
  width: 290px;
  height: 118px;
  text-align: center;
  font-size: var(--font-size-xl);
}
.group-container,
.group-parent3 {
  position: absolute;
  top: 298px;
  left: 884px;
  width: 327px;
  height: 304px;
}
.group-container {
  top: 758px;
  left: -1px;
  width: 1282px;
  height: 723px;
  font-size: var(--font-size-26xl);
}
.vivamus-urna-fermentum-massa-e {
  margin: 0;
}
.lorem-ipsum-dolor-container {
  position: absolute;
  top: 1460px;
  left: 129px;
  font-size: var(--font-size-xl);
  line-height: 141.4%;
  text-align: center;
  display: inline-block;
  width: 1023px;
}
.justo-feugiat,
.lorem-ipsum-dolor2 {
  font-family: var(--font-montserrat);
  color: var(--color-white);
}
.justo-feugiat {
  font-weight: 700;
  color: var(--color-blue);
}
.lorem-ipsum-dolor-sit-amet-co {
  margin-block-start: 0;
  margin-block-end: 19px;
}
.lorem-ipsum-dolor-container1 {
  position: absolute;
  top: 1659px;
  left: 129px;
  font-size: var(--font-size-xl);
  line-height: 141.4%;
  text-align: center;
  display: inline-block;
  width: 1023px;
}
.group-child12,
.home-child4 {
  position: absolute;
  top: 1873px;
  left: 571px;
  width: 137px;
  height: 0;
}
.group-child12 {
  top: 0;
  left: 0;
  border-radius: var(--br-3xs);
  background-color: var(--color-blue);
  box-shadow: 4px 4px 44px rgba(45, 0, 247, 0.5);
  width: 266px;
  height: 62px;
}
.join-xl {
  position: absolute;
  top: 0;
  left: 45px;
}
.starwide-icon,
.starwide-icon1 {
  position: absolute;
  height: 70%;
  width: 10.88%;
  top: 16.67%;
  right: 89.12%;
  bottom: 13.33%;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.starwide-icon1 {
  right: 0;
  left: 89.12%;
}
.join-xl-parent {
  position: absolute;
  top: 16px;
  left: 37px;
  width: 193px;
  height: 30px;
}
.rectangle-parent2 {
  position: absolute;
  top: 1902px;
  left: 507px;
  width: 266px;
  height: 62px;
  font-size: 25px;
}
.home {
  position: relative;
  border-radius: 20px;
  background-color: #0b0b0c;
  width: 100%;
  height: 2015px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-lgi);
  color: var(--color-white);
  font-family: var(--font-montserrat);
}
