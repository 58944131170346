@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;0,900;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montaga:wght@400&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-montserrat: Montserrat;
  --font-montaga: Montaga;

  /* font sizes */
  --font-size-xl: 20px;
  --font-size-26xl: 45px;
  --font-size-lgi: 19px;
  --font-size-41xl: 60px;
  --font-size-mini: 15px;
  --font-size-mid: 17px;

  /* Colors */
  --color-gray-100: rgba(255, 255, 255, 0.02);
  --color-white: #fff;
  --color-blue: #2d00f7;

  /* border radiuses */
  --br-3xs: 10px;
}
